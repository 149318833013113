.Star-icon {
  height: 30px;
  width: 30px;
  margin: 0px 20px;
  padding: 0px 0px 0px 0px ;
  cursor: pointer;
  display: inline-block;
}

.inline {
  display: inline-block;
  /* margin: 0px; */
}

.Session-header-left {
  display: inline-block;
}
.Session-header-right {
  float: right;
  padding: 10px 0px;
  margin-top: 20px;
}
.Session-header {
  position:relative;
}
.Session-download-button {
  /* float: right; */
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  /* object-fit: cover; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  margin: 0 5px;
  float: right;
}
.Session-download-button img {
  height: 20px;
  /* width: 20px; */
  padding: 10px;
  object-fit: contain;
}
.Session-download-button:hover {
  background-color: #F5F7F9;
}

.Session-content {
  display: flex;
  width: 100%;
}

.Toggle {
  background-color: #F1F1F1;
  /* padding: 5px; */
  margin: 0px 5px;
  /* display: inline-block; */
  border-radius: 5px;
  float: right;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.Data-left {
  /* height: 40vh; */
  flex: 1 1 auto;
}
.Data-right {
  /* width: 45vw;
  float: right; */
  flex: 1 1 auto;
}
.Data-left img, .Data-right img {
  width: 100%;
}


.Toggle-fake {
  height: 40px;
  float: right;
  margin: 10px 0;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); */
}

/* .Toggle-fake {
  height: 40px;
  width: 100px;
  float: right;
  margin: 10px 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
} */

/*** SessionDataEntry ***/ 

.calendar-wrapper {
  height: 100%;
  overflow-y: scroll;
}
.rbc-calendar {
  height: 150%;
  /* overflow: scroll; */
}
.rbc-month-view[aria-label="Month View"] {
  height: 150%;
  /* overflow: scroll; */
}
.rbc-time-content {
  overflow-y: inherit;
}
.rbc-time-header.rbc-overflowing {
  margin-right: 0 !important;
}
.rbc-event {
  /* padding: 5px 10px; */
  width: 100%;
  margin: 0;
}
.rbc-month-row {
  overflow: visible;
}
.rbc-event:focus {
  outline: none;
}

.session-data-entry-time {
  min-width: 50px;
  padding: 10px 0;
}
.rbc-event-content {
  width: 100%;
  overflow: visible !important;
  pointer-events: none;
}
.rbc-event-content, .rbc-event-content p, .rbc-event-content h3 {
  color: black;
  display: inline-block;
  font-size: small;
}
.rbc-event-content p, .rbc-event-content h3 {
  margin-left: 5px;
}
.rbc-event-content p {
  text-overflow: ellipsis;
}
.rbc-event-content > div {
  display: flex;
  width: 100%;
}

.rbc-show-more {
  color: #36A0EB;
  padding: 0 5px;
}
.event-content {
  width: 100%;
  display: inline-block;
  padding: 2px;
  border-radius: 8px;
  overflow-x: hidden !important;
}
.event-time {
  margin-right: 2px;
  padding: 2px;
  color: black;
  min-width: 40px;
}

/*** Week view ***/

.rbc-event-label {
  color: black;
  display: none;
}
.rbc-time-content .event-time {
  display: none;
}
.rbc-time-content .rbc-event {
  border: none;
  overflow: visible;
}
.rbc-day-slot .rbc-events-container {
  margin: 0;
}
.rbc-day-slot .rbc-event-content {
  line-height: normal;
}



.tooltiptext-positioner {
  width: 0px;
  height: 0px;
  position: relative;
  top: 30px;
  z-index: 10;
  overflow: visible;
}

.rbc-event .tooltiptext {
  visibility: hidden;
  width: max-content;
  /* height: 120px; */
  background-color: white;
  color: black;
  /* text-align: center; */
  padding: 10px;
  /* border: solid black 2px; */
  border-radius: 6px;
  position: relative;
  z-index: 100;
  /* overflow: scroll; */
  white-space: initial;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
}
.rbc-event:hover .tooltiptext {
  visibility: visible;
}
.tooltiptext div {
  display: block;
}
.rbc-event .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 10px; 
  margin-top: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.event-filter {
  color: black;
  background-color: #36A0EB;
  padding: 2px 12px;
  border: 2px solid;
  border-radius: 20px;
  margin: 2px;
  transition: all 0.5s;
  /* position: relative;
  top: 10px; */
}

.event-filter:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}