.Navbar {
  color: #7F7F83;
  background-color: #F5F7F9;
  height: max-content;
  width: 100vw;
  /* max-width: 100vw; */
  min-width: 230px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  /* top: 0; */
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.Navbar-wrapper {
  width: 100%;
  max-width: 1000px;
  padding: 0 30px 0 30px;
}

.Navbar-items {
  /* display: inline-block; */
  height: 20px;
  padding: 20px;
  float: left;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
h3.Navbar-items:hover, .menu-item:hover {
  text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
}
.Navbar-items-selected {
  background: linear-gradient(124.2deg, #52A3FF 29.63%, #31B2FF 65.76%, #35C7FF 107.64%);
  color: white;
}

.Navbar-logo {
  height: 40px;
  margin: 10px 20px;
  float: left
}

.Navbar-pfp {
  height: 40px;
  width: 40px;
  margin: 10px 20px;
  border-radius: 50%;
  position: relative; 
  z-index: 20; 
  object-fit: cover;
  cursor: pointer;
  float: right;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}
.Navbar-pfp:hover {
  background-color: #F5F7F9;
}

.Navbar-searchbar {
  background-color: white;
  height: 20px;
  width: 40%;
  max-width: 200px;
  min-width: 150px;
  margin: 10px 0px;
  border-radius: 20px;
  padding: 10px 10px;
  float: right;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

#Navbar-login-button {
  margin: 12px 20px;
  float: right;
}

.Dropdown-wrapper {
  height: 0px;
  width: 0px;
  float: right;
  position: relative;
}

/*** Sidebar ***/ 

.bm-burger-button {
  position: absolute;
  width: 80px;
  height: 100%;
  top: 0;
}
.bm-overlay, #sidebar {
  right: 0;
}
#sidebar {
  background-color: #F5F7F9;
  padding: 40px 0;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.3);
}
.bm-item-list {
  color: #b8b7ad;

}
.menu-item {
  padding: 10px 20px;
  transition: all 0.2s ease-out;
}
.menu-item-selected {
  /* background-color: #52A3FF; */
  background: linear-gradient(124.2deg, #52A3FF 29.63%, #31B2FF 65.76%, #35C7FF 107.64%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.menu-item-selected h3 {
  color: white; 
}
.bm-cross {
  background: #52A3FF;
}