.dropshadow {
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.4));
}

p {
  margin: 0;
}


#profile-dropdown-background {
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: 15; */
}

#profile-dropdown {
  height: auto;
  width: 280px; 
  background-color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* z-index: 15; */
  padding: 10px 15px;
}
#profile-dropdown-content {
  width: 100%; 
}

a.profile-dropdown-items {
  display: block;
  color: #7F7F83; 
  /* font-size: 20px; */
  width: 100%; 
  text-decoration: none;
  padding: 5px 0;
  transition: all 0.2s ease-out;
}
a.profile-dropdown-items:hover {
  background-color: #F5F7F9;
}
/* a.profile-dropdown-items:hover {
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.2));
} */
/* #profile-dropdown-header {
  color: grey;
} */
/* #profile-dropdown-username {
  color: black;
  font-size: 20px;
} */

