#login-form {
    width: 100%;
    max-width: 300px;
}

#login-form input {
    box-sizing: border-box;
    width: 100%;
    /* padding: 8px 15px; */
}

#login-form button {
    display: block;
    width: 100%;
    float: none;
    margin: 5px 0px;
}

#login-form a {
    display: block;
    width: 100%;
    margin: 10px 0 30px 0;
}