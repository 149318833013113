@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root {
  --red: #FC94A5;
  --green: #65C97A;
  --yellow: #E7A03C;

  --preventer: #FFD388;
  --reliever: #A8E3FF;
  --attack: #FFAFAB;
  --acq: #E8BFFF;
  --peak: #D1DEFF;
  --oral: #C4F8B3;
}

.App {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* min-width: 400px; */
  font-family: 'Poppins';
  height: 100vh;
}

.App-content {
  background-color: #FBFCFD;
  box-sizing: border-box;
  /* height: 100vh; */
  width: 100vw;
  /* min-width: 400px; */
  /* display: flex;
  flex-direction: column;
  flex: 10 1 auto; */
  /* align-items: flex-start; */
  /* padding: 100px 50px 0px 50px; */
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  flex: 10 1 auto;
  padding: 0;
}
.App-content-wrapper {
  width: 100%;
  height: 100%;
  max-width: 1000px;
  padding: 0;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  flex: 1 1 auto;
  position: relative;
}

.no-scroll {
  overflow-y: hidden;
}
.no-pad {
  padding: 0 !important; 
  max-width: none;
}
.add-pad {
  padding: 0 30px 0 30px;
}
.no-side-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.display-block {
  display: block;
}

p, li, span {
  margin: 0;
  color: #7F7F83
}

.info-page p {
  margin: 16px 0;
  text-align: justify;
}

h2 {
  margin: 0;
  color: #36A0EB;
  font-weight: 400;
}

h3 {
  margin: 0;
  font-size: medium;
  color: #36A0EB;
}

h4 {
  margin-bottom: 0;
}

.blue-font {
  color: #36A0EB;
}

button {
  background-color: #F1F1F1;
  font-family: 'Poppins';
  padding: 8px 15px;
  margin: 5px;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.Button-selected:hover, .Button-white:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.Button-selected {
  padding: 8px 15px;
  background: linear-gradient(124.2deg, #52A3FF 29.63%, #31B2FF 65.76%, #35C7FF 107.64%);
  color: white;
}

.Button-white {
  padding: 8px 15px;
  background: white;
  color: #36A0EB;
}

.Maintenance {
  display: flex;
  height: 40vh;
  width: 100%;
  flex-direction: column;
  padding: 30vh 0;
  text-align: center;
}
.Maintenance img {
  height: 25vh;
}

a {
  text-decoration: none;
}

.Link-accent {
  color: #36A0EB;
  text-decoration: underline;
  text-decoration-color: #36A0EB;
  cursor: pointer;
  transition: all 0.5s ease-out;
  width: fit-content;
}
.Link-accent:hover {
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);  
}

.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.float-right {
  float: right !important;
}

input {
  padding: 6px 15px;
}

.Button-round {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  /* display: flex;
  flex-direction: column; */
  padding: 6px;
  /* object-fit: cover; */
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); */
  margin: 10px 5px;
}

.flex-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flex-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loading-icon {
  height: 40px;
  width: 40px;
}