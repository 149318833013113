.aap-section {
    min-width: 700px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin: 20px 0px;
    background-color: white;
    transition: width 2s ease-out;
}

.aap-row {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    /* min-width: 800px; */
    border-radius: 10px;
}

.aap-col {
    /* flex: 20; */
    width: 20%;
    margin-right: 10px;
}
.aap-row h4 {
    margin-top: 15px;
    margin-left: 15px;
}
h4.aap-col {
    /* flex: 20; */
    /* width: 130px; */
    min-width: 130px;
    margin-right: 10px;
    vertical-align: bottom;
}

.aap-heading h4 {
    /* padding: 6px 17px; */
    /* width: 20%;
    margin: 0 30px 0 20px */
}

input.aap-col {
    margin-top: 10px;
    height: 24px;
    width: 18%;
}