#contact-form {
    /* width: 100%; */
    display: flex;
} 
@media only screen and (max-width: 1101px) {
    #contact-form {
        display: block;
        /* width: 80%; */
    } 
} 
input, textarea {
    border-radius: 5px;
    border: solid 2px #36A0EB;
    /* border-color: #36A0EB; */
    display: block;
    margin-bottom: 10px;
    font-family: 'Poppins';
}

input:focus, textarea:focus {
    border: solid 2px #36A0EB;
}

textarea {
    width: 97%;
    min-height: fit-content;
    resize: vertical;
}

#Contact-submit {
    float: right;
    padding: 8px 15px;
}

.Contact-left {
    flex: 1 1 auto;
}
.Contact-right {
    flex: 4 1 auto;
}
.Landing-section-pad {
    max-width: 800px;
    padding: 0px 80px 0px 80px;
}