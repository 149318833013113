.info-card-container {
    width: 500px;
    /* width: 92%; */
    max-width: 50vw;
    /* height: 120px; */
    padding: 30px;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
    border-radius: 15px;
    /* margin: 20px 10px; */
    background-color: white;
    transition: width 2s;
}

.info-card-container p {
    width: 100%;
    overflow: auto;
}

.info-card-container img {
    width: 100%;

    max-height: 200px !important;
}

/*** SessionDataEntry ***/ 
.session-data-entry-wrapper {
    padding: 5px;
}
.session-data-entry-wrapper h3, .session-data-entry-wrapper p {
    color: black;
}
.session-data-entry {
    width: 100%;
    border-radius: 15px;
    padding: 10px 20px;
}

.session-data-entry-time {
    min-width: 50px;
    padding: 10px 0;
}

.admin-card-container {
    cursor: pointer;
    background-color: white;
    border-radius: 10px;
    padding: 5px 10px;
    transition:  all 0.2s ease-out;
}
.admin-card-container:hover {
    cursor: pointer;
    background-color: #F5F7F9;
}