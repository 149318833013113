.Dashboard {
  display: flex;
  flex-direction: row;
}

.Dashboard h1 {
  margin: 0 10px;
}

.Section {
  /* flex: 1 1 auto; */
  padding: 20px 10px 10px 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin: 20px 10px;
  background-color: white;
  transition: width 2s ease-out;
}

.column-left {
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  min-width: 150px;
  text-overflow: ellipsis;
  transition: width 2s ease-out;
  /* overflow-y: auto; */
}

.column-right {
  flex: 1 1 auto;
  height: 85%;
  width: 100%;
  max-width: 40vw;
  display: flex;
  flex-direction: column;
  transition: width 2s ease-out;
}

/* .Section-preview {
  display: flex;
  flex-direction: column;
} */
.Section-preview-header {
  width: 100%;
}

.Section-preview-content {
  flex: 1 1 auto;
  display: flex;
  height: 70vh;
}

.Section-preview-empty {
  width: 70%;
  text-align: center;
  display:inline-block;
  padding: 0 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Section-preview-report {
  height: 90%;
  width: 90%;
  /* display: inline-block; */
  padding: 0 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.Session-entry {
  cursor: pointer;
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-out;
}

.Session-star {
  margin: 0;
  transition: all 0.2s ease-out;
}
.Session-star:hover {
  filter: saturate(0);
}
.Session-star-hollow {
  opacity: 0;
  filter: saturate(0) brightness(1);
  transition: all 0.2s ease-out;
}
.Session-star-hollow-visible {
  opacity: 1;
  /* filter: saturate(0) brightness(1);
  transition: all 0.2s ease-out; */
}
.Session-star-hollow:hover {
  filter: saturate(1);
  transition: all 0.2s ease-out;
}
.Session-entry:hover .Session-star-hollow {
  opacity: 1;
}

.Session-entry-selected .Session-star:hover {
  filter: saturate(0) brightness(100);
}
.Session-entry-selected .Session-star-hollow {
  opacity: 1;
  filter: saturate(0) brightness(100);
}
.Session-entry-selected .Session-star-hollow:hover {
  filter: saturate(1) brightness(1);
}


.Session-star-wrapper .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}
.Session-star-wrapper:hover .tooltiptext {
  visibility: visible;
}
.Session-star-wrapper .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.Session-entry:hover {
  background-color: #F5F7F9;
}

.Session-entry-selected {
  background: linear-gradient(124.2deg, #52A3FF 29.63%, #31B2FF 65.76%, #35C7FF 107.64%);
}

.Session-entry-selected p, .Session-entry-selected h3 {
  color: white;
}

.Session-details {
  display: inline-block;
  height: 48px;
  overflow: hidden;
}

.Session-details p, .Session-details h3 {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.Section-preview-button {
  float: right;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  /* object-fit: cover; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  margin: 0 5px;
}
.Section-preview-button img {
  height: 20px;
  /* width: 20px; */
  padding: 10px;
  object-fit: contain;
}
.Section-preview-button:hover {
  background-color: #F5F7F9;
}

.Section-preview-data div {
  padding: 10px 20px;
}

.Section-preview-data h3 {
  display: inline-block;
  width: 45px;
  margin-right: 10px;
}

.Section-preview-data p {
  display: inline-block;
}

.column-left::-webkit-scrollbar {
  width: .4em; 
}
.column-left::-webkit-scrollbar,
.column-left::-webkit-scrollbar-thumb {
  overflow:visible;
  border-radius: 4px;
}
.column-left::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.1); 
}