.Landing-first-row img {
    height: auto;
    width: 100%;
    min-width: 300px;
    padding-bottom: 0 !important;
}

.Landing-heading-large {
    font-size: 2em;
    margin-top: 60px;
    margin-bottom: 0;
}
@media screen and (min-width: 450px) {
    .Landing-heading-large {
        font-size: 3em;
    }
}

.Landing-row-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Landing-row {
    display: flex;
    /* width: 100%; */
    max-width: 940px;
    text-align: center;
    padding: 30px;
}
.Landing-row h1 {
    margin: 0;
}
.Landing-section {
    width: 90%;
    max-width: 940px;
    padding: 0px 30px 50px 30px;
}
.accent-row {
    background: linear-gradient(124.2deg, #52A3FF 29.63%, #31B2FF 65.76%, #35C7FF 107.64%);
}
.accent-row h1, .accent-row h2, .accent-row p {
    color: white;
}
.grey-row {
    background: #F5F7F9;
}
ul {
    text-align: left;
}
.Landing-first-row {
    /* padding-top: 400px; */
    padding-bottom: 0px;
    text-align: start;
}
#Landing-catchphrase1 {
    margin: 0;
    position: relative;
    top: 24px;
    font-size: 3em;
    margin: 0;
}
#Landing-catchphrase2 {
    font-size: 3em;
    margin: 0;
}
@media screen and (min-width: 450px) {
    #Landing-catchphrase2 {
        font-size: 4em;
        margin: 0;
    }
}

.Landing-col {
    /* padding: 30px 0px; */
    /* align-items: center; */
    /* width: 50%; */
    min-width: 400px;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
}

.Landing-mid {
    text-align: center;
}
.Landing-mobile-images {
    width: 100%
}

.team-profile-card {
    width: 230px;
    padding: 5px 20px;
}
.team-profile-card img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.flex-wrap {
    flex-wrap: wrap;
    justify-content: center;
    /* padding: 0; */
}

.partners-logos {
    height: 80px;
}

.quote1 {
    font-size: 100px;
    font-style: italic;
    height: 0px;
    position: relative;
    right: 20px;
    bottom: 40px;
}
.quote2 {
    font-size: 100px;
    font-style: italic;
    height: 0px;
    position: relative;
    right: 20px;
    bottom: 40px;
    text-align: end;
}
.quote-content {
    padding: 0 20px;
}

.Landing-col img {
    width: 80%;
    padding: 10px 10%
}