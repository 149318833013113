.form-radio {
    display: inline-block;
    height: 16px;
    width: 16px;
    margin-right: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: #36A0EB 2px solid;
    border-radius: 50%;
    padding: 0;
    transition: 0.2s all linear;
    /* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.4)); */
    position: relative;
    top: 12px;
    cursor: pointer;
}
.form-radio:checked {
    border: #36A0EB 8px solid;
    background-color: #36A0EB;
}
.form-radio-label {
    display: block;
    color: grey;
}

.form-radio-label {
    cursor: pointer;
}